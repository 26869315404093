import { RouterStore } from "mobx-router";
import { OnboardStore } from "./stores/OnboardStore";
import { SDKProvider } from "../types/sdk-provider";
import { ContractStore } from "./stores/ContractStore";
import UiState from "./reducers";
import { NetworkStore } from "./stores/NetworkStore";
import UserStore from "./stores/UserStore";

export class RootStore {
  public router: RouterStore<RootStore>;
  public contracts: ContractStore;
  public onboard: OnboardStore;
  public uiState: UiState;
  public network: NetworkStore;
  public user: UserStore;

  constructor() {
    this.router = new RouterStore<RootStore>(this);
    this.onboard = new OnboardStore(this);
    this.contracts = new ContractStore(this);
    this.uiState = new UiState(this);
    this.network = new NetworkStore(this);
    this.user = new UserStore(this);

    this.network.updateGasPrices();
  }

  // the provider wiring is not needed or used / required for move to sdk based app
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  async updateProvider(provider: SDKProvider): Promise<void> {
    const signer = provider.getSigner();
    let updateActions: Promise<void>[] = [];
    if (signer && this.onboard.address) {
      const address = await signer.getAddress();
      updateActions = updateActions.concat([this.user.reloadBalances(address)]);
    }
    await Promise.all(updateActions);
  }
}

const store = new RootStore();

export default store;
