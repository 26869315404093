import { RootStore } from "../RootStore";
import { init } from "@web3-onboard/react";
import { BLOCKNATIVE_API_KEY, WC_PROJECT_ID } from "../../config/constants";
import injectedModule from "@web3-onboard/injected-wallets";
import walletConnect from "@web3-onboard/walletconnect";
import coinbaseWallet from "@web3-onboard/coinbase";
import gnosis from "@web3-onboard/gnosis";
import ledger from "@web3-onboard/ledger";
import XDEFIWallet from "@web3-onboard/xdefi";

import type {
  EIP1193Provider,
  OnboardAPI,
  WalletState,
} from "@web3-onboard/core";
import { extendObservable } from "mobx";

export class OnboardStore {
  public wallet?: WalletState;
  public onboard: OnboardAPI;
  public provider?: EIP1193Provider;
  public address?: string;

  constructor(private store: RootStore) {
    const injected = injectedModule();
    const wcConnector = walletConnect({
      projectId: WC_PROJECT_ID,
      dappUrl: "https://tokens.code4rena.com/",
    });
    const cbConnector = coinbaseWallet();
    const gnosisConnector = gnosis();
    const ledgerConnector = ledger({
      walletConnectVersion: 2,
      projectId: WC_PROJECT_ID,
    });
    const xdConnector = XDEFIWallet();

    this.onboard = init({
      wallets: [
        injected,
        wcConnector,
        cbConnector,
        gnosisConnector,
        ledgerConnector,
        xdConnector,
      ],
      chains: [
        {
          id: 137,
          token: "MATIC",
          label: "Matic Mainnet",
          rpcUrl: "https://matic-mainnet.chainstacklabs.com",
        },
      ],
      appMetadata: {
        // TODO: Add DApp metadata
        name: "ARENA Token Claim DApp",
        // icon?: string;
        // logo?: string;
        description:
          "DApp for interacting with the ARENA token distro contracts",
        // explore?: string;
      },
      apiKey: BLOCKNATIVE_API_KEY,
      notify: {
        enabled: true,
        position: "topRight",
      },
    });
    extendObservable(this, {
      onboard: this.onboard,
      provider: undefined,
      address: undefined,
    });
  }

  async connect(): Promise<boolean> {
    const walletArray = await this.onboard.connectWallet();
    if (walletArray[0]) {
      this.wallet = walletArray[0];
      this.address = walletArray[0].accounts[0].address;
      this.provider = walletArray[0].provider;
      return true;
    }
    return false;
  }

  async disconnect() {
    try {
      this.store.user.clearBalances();
      if (this.wallet?.label) {
        await this.onboard.disconnectWallet({ label: this.wallet.label });
      }
      this.wallet = undefined;
      this.address = undefined;
      this.provider = undefined;
    } catch {} // ignore disconnect failures from provider
  }
}
