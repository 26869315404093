import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { StoreContext } from "../../mobx/store-context";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import GasWidget from "../GasWidget";
import WalletWidget from "../WalletWidget";
import logo from "../../images/c4-logo.svg";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    borderBottom: "1px solid #484061",
    position: "sticky",
    top: 0,
    background: theme.palette.background.default,
    zIndex: 1,
    padding: theme.spacing(2.5, 6, 2.5, 6),
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  button: {
    height: 36,
    marginRight: "10px",
    minWidth: "100px",
  },
  buttonContainer: {
    flex: "1",
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      marginTop: "10px",
    },
  },
  logoContainer: {
    width: "100%",
    alignItems: "center",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      marginBottom: "10px",
    },
  },
  logoSvg: {
    maxWidth: "150px",
    minWidth: "150px",
    width: "100%",
    marginBottom: "-2px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "-4px",
    },
  },
  logoLink: {
    alignSelf: "center",
  },
  divider: {
    display: "inline-block",
    height: "80%",
    minHeight: "30px",
    width: "1px",
    background: "#484061",
    alignSelf: "center",
    margin: "0 20px",
  },
  subtitle: { margin: "0", alignSelf: "center", fontSize: "16px" },
}));

const Header = observer(() => {
  const {
    uiState: { notification },
  } = useContext(StoreContext);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const enq = () => {
    if (!notification || !notification.message) return;

    enqueueSnackbar(notification.message, {
      variant: notification.variant,
      persist: false,
    });
  };
  // Disable reason: Hook used for execution of enq() on change of notification.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(enq, [notification]);

  return (
    <div className={classes.root}>
      <div className={classes.logoContainer}>
        <a href="/" className={classes.logoLink}>
          <img src={logo} alt="Code4rena Logo" className={classes.logoSvg} />
        </a>
        <span className={classes.divider}></span>
        <h1 className={classes.subtitle}>CLAIM TOKENS</h1>
      </div>

      <div className={classes.buttonContainer}>
        <GasWidget className={classes.button} />
        {window?.location?.pathname !== "/not-legal" && (
          <WalletWidget className={classes.button} />
        )}
      </div>
    </div>
  );
});

export default Header;
