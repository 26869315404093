import { GasPrices } from "@badger-dao/sdk";
import { action, extendObservable } from "mobx";
import { RootStore } from "../RootStore";

interface BlockscanGasPricesResponse {
  result: {
    mingaspricegwei: number;
    standardgaspricegwei: number;
    fastgaspricegwei: number;
    rapidgaspricegwei: number;
  };
}

export class NetworkStore {
  private store: RootStore;
  public gasPrices: GasPrices | null;
  public currentBlock: number;

  constructor(store: RootStore) {
    this.store = store;
    this.gasPrices = null;
    this.currentBlock = 1;

    extendObservable(this, {
      currentBlock: this.currentBlock,
      gasPrices: this.gasPrices,
    });
  }

  updateGasPrices = action(async () => {
    const res = await fetch(
      "https://gpoly.blockscan.com/gasapi.ashx?apikey=key&method=pendingpooltxgweidata"
    );
    const body = (await res.json()) as BlockscanGasPricesResponse;
    const prices: GasPrices = {
      rapid: body.result.rapidgaspricegwei,
      fast: body.result.fastgaspricegwei,
      standard: body.result.standardgaspricegwei,
      slow: body.result.mingaspricegwei,
    };
    this.gasPrices = prices;
  });
}
