import React from "react";
import { QueryParams, Route } from "mobx-router";
import Landing from "../components/Landing";
import { RootStore } from "../mobx/RootStore";
import { NotFound } from "../components/NotFound";
import { NotLegal } from "../components/NotLegal";

const routes = {
  home: new Route<RootStore, QueryParams>({
    path: "/",
    component: <Landing title="Claim your $ARENA" subtitle="" />,
  }),
  notFound: new Route<RootStore, QueryParams>({
    path: "/not-found",
    component: <NotFound />,
  }),
  notLegal: new Route<RootStore, QueryParams>({
    path: "/not-legal",
    component: <NotLegal />,
  }),
};

export default routes;
