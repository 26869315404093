import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "./Header";
import { MobxRouter } from "mobx-router";
import { useEffect } from "react";
import { ONE_MIN_MS } from "../config/constants";
import { useContext } from "react";
import { StoreContext } from "../mobx/store-context";
import { BigNumber } from "bignumber.js";

BigNumber.config({ EXPONENTIAL_AT: 1e+9 })

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    paddingBottom: theme.spacing(4),
  },
}));

export const App = (): JSX.Element => {
  const classes = useStyles();
  const store = useContext(StoreContext);

  // network data updating
  useEffect(() => {
    const networkInterval = setInterval(async () => {
      await store.network.updateGasPrices();
    }, ONE_MIN_MS / 2);
    return () => clearInterval(networkInterval);
  });

  return (
    <div className={classes.root}>
      <div className={classes.contentContainer}>
        <Header />
        <main className={classes.content}>
          <MobxRouter store={store} />
        </main>
      </div>
    </div>
  );
};
