import importedArenaToken from "./abis/ArenaToken.json";
import importedRevokableTokenLock from "./abis/RevokableTokenLock.json";
import { AbiItem, toTwosComplement } from "web3-utils";

export const BLOCKNATIVE_API_KEY = "5fe86293-14f6-425c-be82-7021f3970c94";

// Non-sensitive temporary WalletConnect projectId
// w/ whitelisted code4rena.com domain (cant be used on other domains)
export const WC_PROJECT_ID = "711f16b983528009992eecece97b8e63";

// @todo: get our own portis api key
// export const PORTIS_APP_ID = "";
export const APP_NAME = "Claim ARENA Code4rena";
export const CONTACT_EMAIL = "hello@code4rena.com";

export const MAX = toTwosComplement(-1);

export const ONE_MIN_MS = 60 * 1000;

export const ARENA_TOKEN_ABI = importedArenaToken.abi as AbiItem[];
export const REVOKABLE_TOKEN_LOCK_ABI =
  importedRevokableTokenLock.abi as AbiItem[];

export const ARENA_TOKEN = "0x6847D3A4c80a82e1fb26f1fC6F09F3Ad5BEB5222";
export const REVOKABLE_TOKEN_LOCK =
  "0xB17828789280C77C17B02fc8E6F20Ddc5721f2C2";
export const TIMELOCK_CONTROLLER = "0xdFB26381aFBc37f0Fae4A77D385b91B90347aA12";
