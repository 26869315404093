import React from "react";
import { Typography, makeStyles } from "@material-ui/core";

export interface PageHeaderProps {
  title: string;
  subtitle?: string;
}

const useStyles = makeStyles((theme) => ({
  headerWidgets: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  headerContainer: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
    alignItems: "center",
    justifyContent: "space-between",
  },
  mainTitle: {
    textAlign: "center",
    width: "100%",
    fontSize: "33px",
    fontWeight: "bold",
    fontFamily: "roboto",
    marginTop: theme.spacing(4),
  },
}));

const PageHeader: React.FC<PageHeaderProps> = (props: PageHeaderProps) => {
  const classes = useStyles();
  const { title, subtitle } = props;
  return (
    <div className={classes.headerContainer}>
      <Typography
        variant="h6"
        color="textPrimary"
        className={classes.mainTitle}
      >
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="body2" color="textSecondary">
          {subtitle}
        </Typography>
      )}
    </div>
  );
};

export default PageHeader;
