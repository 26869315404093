import React, { useContext, useEffect, useState } from "react";
import { Button, Grid, makeStyles, Paper } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import {
  ARENA_TOKEN,
  ARENA_TOKEN_ABI,
  REVOKABLE_TOKEN_LOCK,
  TIMELOCK_CONTROLLER,
} from "../../config/constants";
import { StoreContext } from "../../mobx/store-context";
import { BigNumber } from "bignumber.js";
import { Provider } from "../../config/provider.config";
import Web3 from "web3";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PageHeader from "./PageHeader";
import { convertFromWei } from "../../utils/helpers";

interface LandingProps {
  title: string;
  subtitle?: string;
}

export interface Token {
  name: string;
  address: string;
}

const useStyles = makeStyles((theme) => ({
  extLinkIcon: {
    paddingTop: "5px",
  },
  swapPaper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(4, 6),
    marginLeft: "10px",
    marginRight: "10px",
    height: "100%",
  },
  inputContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginTop: theme.spacing(1.5),
    borderRadius: ".625rem",
  },
  inputDecorationText: {
    fontWeight: "bold",
    color: theme.palette.text.primary,
  },
  inputText: {
    fontSize: "1.5rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
      marginTop: "auto",
    },
  },
  inputDisplayContainer: {
    height: "100%",
    alignItems: "flex-end",
  },
  inputHeaderText: {
    color: theme.palette.text.primary,
    fontWeight: "bolder",
    fontSize: "1.5rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
      marginTop: "auto",
    },
  },
  swapIcon: {
    fontSize: "4rem !important",
    marginTop: theme.spacing(4),
  },
  wrapButton: {
    padding: "6px 30px",
    background: theme.palette.primary.main,
    border: "2px solid transparent",
    color: "white",
    "&:hover": {
      background: theme.palette.primary.main,
      borderColor: "white",
      color: "white",
    },
  },
  ppfsText: {
    marginTop: `${theme.spacing(4)}px !important`,
    textAlign: "center",
  },
  linkContainer: {
    marginTop: theme.spacing(2),
  },
  link: {
    color: "#F2A52B !important",
    textDecoration: "underline !important",
  },
  balance: {
    marginTop: "10px",
    marginBottom: "10px",
    fontSize: "30px",
    fontWeight: "bold",
    textAlign: "center",
    alignSelf: "center",
    margin: "0",
  },
  balanceContainer: {
    background: `${theme.palette.background.default}`,
    borderRadius: "6px",
    padding: theme.spacing(3),
  },
  balanceHint: {
    textAlign: "center",
    overflowWrap: "anywhere",
    display: "block",
    textTransform: "uppercase",
    fontSize: "14px",
    fontWeight: "normal",
    letterSpacing: "2px",
  },
  hr: { marginTop: "20px", marginBottom: "20px" },
}));

const Landing = observer((props: LandingProps) => {
  const classes = useStyles();
  const { title, subtitle } = props;
  const store = useContext(StoreContext);
  const {
    onboard: { address },
    user,
    contracts: { claimTokens, claim },
  } = store;

  const arena = {
    address: ARENA_TOKEN,
    name: "ARENA",
  };
  const userDetails = user.getTokenBalance(arena.address);

  useEffect(() => {
    user.reloadBalances();
  }, [address]);

  const [uncirculating, setUncirculating] = useState("0");
  const getUncirculating = async (arenaToken: any) => {
    const awaitArray = [];
    awaitArray.push(arenaToken.methods.balanceOf(ARENA_TOKEN).call());
    awaitArray.push(arenaToken.methods.balanceOf(REVOKABLE_TOKEN_LOCK).call());
    awaitArray.push(arenaToken.methods.balanceOf(TIMELOCK_CONTROLLER).call());
    const supply = await Promise.all(awaitArray);
    const baseBal = new BigNumber(supply[0]);
    const tokenlockBal = new BigNumber(supply[1]);
    const timelockBal = new BigNumber(supply[2]);
    const uncirc = baseBal.plus(tokenlockBal).plus(timelockBal);
    setUncirculating(uncirc.toString());
  };

  useEffect(() => {
    const web3 = new Web3(Provider.Polygon);
    const arenaToken = new web3.eth.Contract(ARENA_TOKEN_ABI, ARENA_TOKEN);
    getUncirculating(arenaToken);
  }, []);

  const getCircSupply = () => {
    const supply = new BigNumber(1000000000);
    const uncircSupply = supply.multipliedBy(1e18).minus(uncirculating);
    return convertFromWei(uncircSupply, 0);
  };

  const claimable = userDetails.claimable
    .dividedBy(user.pricePerShare)
    .multipliedBy(1e18);

  const defaultClaimable = {
    claimable,
    displayClaimable: convertFromWei(claimable),
  };

  const getFinalDate = () => {
    const timestampBN = userDetails.vestDate.times(1000);
    if (timestampBN.isLessThanOrEqualTo(0)) {
      return false;
    }
    const timestamp = timestampBN.toNumber();
    var date = new Date(timestamp).toLocaleDateString();
    return date;
  };

  const handleClaim = async () => {
    await claim(defaultClaimable.claimable);
    await user.reloadBalances();
  };

  const handleClaimTokens = async () => {
    await claimTokens(defaultClaimable.claimable);
    await user.reloadBalances();
  };

  const watchToken = async () => {
    const tokenAddress = "0x6847D3A4c80a82e1fb26f1fC6F09F3Ad5BEB5222";
    const tokenSymbol = "ARENA";
    const tokenDecimals = 18;
    const tokenImage = "https://polygonscan.com/token/images/code4arena_32.png";

    if (!window.ethereum || !address) {
      navigator.clipboard.writeText(tokenAddress);
    } else {
      try {
        await window.ethereum?.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: tokenAddress, // The address that the token is at.
              symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
              decimals: tokenDecimals, // The number of decimals in the token
              image: tokenImage, // A string url of the token logo
            },
          },
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Grid container spacing={0} justifyContent="center">
        <Grid justifyContent="center" item container xs={12}>
          <Grid item md={6} xs={11}>
            <PageHeader title={title} subtitle={subtitle} />
          </Grid>
        </Grid>
        <Grid item md={6} xs={11} container direction="column">
          <Paper className={classes.swapPaper}>
            <div className={classes.balanceContainer}>
              {!user.isInMerkle && userDetails.locked.isGreaterThan("0") && (
                <h2 className={classes.balance}>
                  <span className={classes.balanceHint}>
                    Your address is not included in the initial merkle, but you
                    have a claim available. If the claim button does not work,
                    please reach out in Discord for assistance
                  </span>
                  <hr />
                </h2>
              )}
              <h2 className={classes.balance}>
                <span className={classes.balanceHint}>
                  {address ? "Claimable" : "Please connect your wallet"}
                </span>
                {defaultClaimable.displayClaimable}
              </h2>
              <Grid justifyContent="center" container>
                <Button
                  onClick={
                    user.hasClaimedAirdrop ? handleClaim : handleClaimTokens
                  }
                  disabled={
                    !address ||
                    defaultClaimable.claimable.lte(0) ||
                    !user.hasClaimedAirdrop
                  }
                  className={classes.wrapButton}
                  variant="contained"
                >
                  {user.hasClaimedAirdrop || !address
                    ? `Claim ${arena.name}`
                    : "Claim Period Expired"}
                </Button>
              </Grid>
              <hr className={classes.hr} />
              <h2 className={classes.balance}>
                <span className={classes.balanceHint}>Locked</span>
                {convertFromWei(userDetails.locked)}
              </h2>
              {getFinalDate() && (
                <h2 className={classes.balance}>
                  <span className={classes.balanceHint}>
                    Final vesting date
                  </span>
                  {getFinalDate()}
                </h2>
              )}
              <hr className={classes.hr} />
              <h2 className={classes.balance}>
                <span className={classes.balanceHint}>
                  Wallet balance
                  {address && (
                    <a
                      href={"https://polygonscan.com/address/" + address}
                      aria-label="open polygonscan"
                    >
                      <OpenInNewIcon className={classes.extLinkIcon} />
                    </a>
                  )}
                </span>
                {convertFromWei(userDetails.balance)}
              </h2>
            </div>
          </Paper>
        </Grid>
        <Grid item md={6} xs={11} container direction="column">
          <Paper className={classes.swapPaper}>
            <div className={classes.balanceContainer}>
              <h2 className={classes.balance}>$ARENA Token</h2>
              <h2 className={classes.balanceHint}>{ARENA_TOKEN}</h2>
              <Grid justifyContent="center" container>
                <Button
                  onClick={watchToken}
                  className={classes.wrapButton}
                  variant="contained"
                >
                  {window.ethereum && address
                    ? "Add ARENA to wallet"
                    : "Copy Address"}
                </Button>
              </Grid>
              <br />
              <h2 className={classes.balance}>
                <span className={classes.balanceHint}>Total Supply</span>
                1,000,000,000
              </h2>
              <h2 className={classes.balance}>
                <span className={classes.balanceHint}>Circulating Supply</span>
                {getCircSupply()}
              </h2>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
});

export default Landing;
