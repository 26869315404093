import { action, extendObservable } from "mobx";
import { RootStore } from "../RootStore";
import Web3 from "web3";

import {
  ONE_MIN_MS,
  ARENA_TOKEN,
  ARENA_TOKEN_ABI,
  REVOKABLE_TOKEN_LOCK,
  REVOKABLE_TOKEN_LOCK_ABI,
} from "../../config/constants";

import MerkleRoots from "../../config/MerkleRoots.json";

import BigNumber from "bignumber.js";

interface UserBalanceCache {
  [key: string]: CachedTokenBalances;
}

interface TokenBalances {
  [contract: string]: TokenBalance;
}

export interface TokenBalance {
  claimable: BigNumber;
  locked: BigNumber;
  vestDate: BigNumber;
  balance: BigNumber;
}

interface CachedTokenBalances {
  key: string;
  tokens: TokenBalances;
  expiry: number;
}

const merkleRoots: Record<
  string,
  { index: number; amount: string; proof: string[] }
> = MerkleRoots.claims;

function merkleIndex(address: string): number | null {
  const record = Object.entries(merkleRoots).find(([a, r]) => {
    return a.toLowerCase() === address.toLowerCase();
  });
  if (record) {
    return record[1].index;
  }

  return null;
}

function getRecordForAddress(
  address: string
): { index: number; amount: string; proof: string[] } | null {
  const record = Object.entries(merkleRoots).find(([a, r]) => {
    return a.toLowerCase() === address.toLowerCase();
  });
  if (record) {
    return record[1];
  }

  return null;
}

export default class UserStore {
  private store: RootStore;
  private userBalanceCache: UserBalanceCache = {};

  // loading: undefined, error: null, present: object
  public tokenBalances: TokenBalances = {};
  public loadingBalances: boolean;
  public pricePerShare: BigNumber;
  public hasClaimedAirdrop: boolean;
  public isInMerkle: boolean;

  constructor(store: RootStore) {
    this.store = store;
    this.loadingBalances = false;
    this.pricePerShare = new BigNumber(1 * 1e18);
    this.hasClaimedAirdrop = false;
    this.isInMerkle = false;

    extendObservable(this, {
      tokenBalances: this.tokenBalances,
      loadingBalances: this.loadingBalances,
      pricePerShare: this.pricePerShare,
      hasClaimedAirdrop: this.hasClaimedAirdrop,
      isInMerkle: this.isInMerkle,
    });
  }

  /* Read Variables */
  async reloadBalances(address?: string): Promise<void> {
    const { onboard } = this.store;
    const actions = [];
    const queryAddress = address ?? onboard.address;

    if (queryAddress) {
      actions.push(this.updateBalances());
      await Promise.all(actions);
    }
  }

  updateBalances = action(
    async (addressOverride?: string, cached?: boolean): Promise<void> => {
      const { address, wallet } = this.store.onboard;

      /**
       * only allow one set of calls at a time, blocked by a loading guard
       * do not update balances without prices available or a provider
       */
      const queryAddress = addressOverride ?? address;
      if (!queryAddress || this.loadingBalances || !wallet?.provider) {
        return;
      }

      this.loadingBalances = true;
      const cacheKey = `${address}`;

      if (cached) {
        const cachedBalances = this.userBalanceCache[cacheKey];
        if (cachedBalances && Date.now() <= cachedBalances.expiry) {
          this.setBalances(cachedBalances);
          this.loadingBalances = false;
          return;
        }
      }

      try {
        const web3 = new Web3(wallet.provider);
        const arenaToken = new web3.eth.Contract(ARENA_TOKEN_ABI, ARENA_TOKEN);
        const tokenLock = new web3.eth.Contract(
          REVOKABLE_TOKEN_LOCK_ABI,
          REVOKABLE_TOKEN_LOCK
        );

        const index = merkleIndex(queryAddress);
        let hasClaimedAirdrop: boolean = true;
        let isInMerkle: boolean = false;
        if (index) {
          isInMerkle = true;
          hasClaimedAirdrop = (await arenaToken.methods
            .isClaimed(index)
            .call()) as boolean;
        }

        let claimable: string = "0";
        let locked: string = "0";
        let vestDate: string = "0";
        let balance: string = "0";
        const balanceQuery = (await arenaToken.methods
          .balanceOf(queryAddress)
          .call()) as string;
        balance = balanceQuery;
        if (hasClaimedAirdrop || !isInMerkle) {
          const claimableBalance = (await tokenLock.methods
            .claimableBalance(queryAddress)
            .call()) as string;
          claimable = claimableBalance;
          const vestingDetails = await tokenLock.methods
            .vesting(queryAddress)
            .call();
          locked = vestingDetails.lockedAmounts;
          vestDate = vestingDetails.unlockEnd;
        } else {
          const record = getRecordForAddress(queryAddress);
          if (record) {
            claimable = record.amount;
          } else {
            claimable = "0";
          }
        }

        const tokenBalances: TokenBalances = {
          [ARENA_TOKEN]: {
            claimable: new BigNumber(claimable),
            locked: new BigNumber(locked),
            vestDate: new BigNumber(vestDate),
            balance: new BigNumber(balance),
          },
        };

        const result = {
          key: cacheKey,
          tokens: tokenBalances,
          expiry: Date.now() + 5 * ONE_MIN_MS,
        };

        this.userBalanceCache[cacheKey] = result;
        this.setBalances(result);
        this.hasClaimedAirdrop = hasClaimedAirdrop;
        this.isInMerkle = isInMerkle;
        this.loadingBalances = false;
      } catch (err) {
        console.error(err);
        this.loadingBalances = false;
      }
    }
  );

  private setBalances = action((balances: CachedTokenBalances): void => {
    const { tokens } = balances;
    this.tokenBalances = tokens;
  });

  private getOrDefaultBalance(
    balances: TokenBalances,
    token: string
  ): TokenBalance {
    const balance = balances[token];
    if (!balance) {
      return {
        claimable: new BigNumber(0),
        locked: new BigNumber(0),
        vestDate: new BigNumber(0),
        balance: new BigNumber(0),
      };
    }
    return balance;
  }

  getTokenBalance(contract: string): TokenBalance {
    const tokenAddress = Web3.utils.toChecksumAddress(contract);
    return this.getOrDefaultBalance(this.tokenBalances, tokenAddress);
  }

  clearBalances() {
    this.setBalances({
      key: "",
      tokens: {},
      expiry: 0,
    });
  }
}
