import Web3 from "web3";
import {
  EIP1559SendOptions,
  getSendOptions,
  sendContractMethod,
} from "../../utils/web3";
import BigNumber from "bignumber.js";
import { RootStore } from "../RootStore";
import { ContractSendMethod, SendOptions } from "web3-eth-contract";
import {
  ARENA_TOKEN,
  ARENA_TOKEN_ABI,
  REVOKABLE_TOKEN_LOCK,
  REVOKABLE_TOKEN_LOCK_ABI,
} from "../../config/constants";
import { extendObservable } from "mobx";
import { GasSpeed } from "../../config/enums/gas-speed.enum";
import MerkleRoots from "../../config/MerkleRoots.json";
import { convertFromWei } from "../../utils/helpers";

const merkleRoots: Record<
  string,
  { index: number; amount: string; proof: string[] }
> = MerkleRoots.claims;

function getRecordForAddress(
  address: string
): { index: number; amount: string; proof: string[] } | null {
  const record = Object.entries(merkleRoots).find(([a, r]) => {
    return a.toLowerCase() === address.toLowerCase();
  });
  if (record) {
    return record[1];
  }

  return null;
}

export class ContractStore {
  private store!: RootStore;

  constructor(store: RootStore) {
    this.store = store;
    extendObservable(this, {});
  }

  /* Contract Interaction Methods */

  // Claims the caller's tokens that have been unlocked.
  // If amount is greater than the claimable amount, the maximum is transferred
  claim = async (amount: BigNumber): Promise<void> => {
    const { queueNotification } = this.store.uiState;
    const {
      onboard: { wallet, address },
    } = this.store;

    if (!wallet?.provider) {
      console.error("Provider not valid when attempting to mint.");
      return;
    }

    if (amount.isNaN() || amount.lte(0)) {
      queueNotification("Please enter a valid amount", "error");
      return;
    }
    const web3 = new Web3(wallet.provider);
    const revokableTokenLock = new web3.eth.Contract(
      REVOKABLE_TOKEN_LOCK_ABI,
      REVOKABLE_TOKEN_LOCK
    );
    const method = revokableTokenLock.methods.claim(address, amount);
    const options = await this.getMethodSendOptions(method);
    const infoMessage = `Transaction to redeem ${amount} submitted`;
    const successMessage = `${convertFromWei(amount)} ARENA redeemed!`;
    await sendContractMethod(
      this.store,
      method,
      options,
      infoMessage,
      successMessage
    );
  };

  // Claims airdropped tokens
  // Param `balance` is the amount of the claim being made (must be the whole balance)
  claimTokens = async (balance: BigNumber): Promise<void> => {
    const {
      onboard: { wallet, address },
    } = this.store;
    if (!wallet?.provider) {
      console.error("Provider not valid when attempting to mint.");
      return;
    }
    if (!address) {
      console.error("No address");
      return;
    }
    const { proof } = getRecordForAddress(address)!;
    const web3 = new Web3(wallet.provider);
    const arenaToken = new web3.eth.Contract(ARENA_TOKEN_ABI, ARENA_TOKEN);
    const method = arenaToken.methods.claimTokens(balance, proof);
    const options = await this.getMethodSendOptions(method);
    const infoMessage = `Transaction to claim airdrop submitted`;
    const successMessage = `Airdrop claimed!`;
    await sendContractMethod(
      this.store,
      method,
      options,
      infoMessage,
      successMessage
    );
  };

  getMethodSendOptions = async (
    method: ContractSendMethod
  ): Promise<SendOptions | EIP1559SendOptions> => {
    const {
      onboard,
      network: { gasPrices },
    } = this.store;
    if (!onboard.address) {
      throw Error("Sending tx without a connected account");
    }
    const price = gasPrices ? gasPrices[GasSpeed.Fast] : 0;
    return await getSendOptions(method, onboard.address, price);
  };
}
