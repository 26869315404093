import { extendObservable, action } from "mobx";
import { RootStore } from "../RootStore";
import { GasSpeed } from "../../config/enums/gas-speed.enum";

class UiState {
  private readonly store!: RootStore;
  public airdropStats: any;
  public sidebarOpen!: boolean;
  public notification: any = {};
  public gasPrice: GasSpeed;
  public txStatus?: string;
  private showNotification: boolean;

  constructor(store: RootStore) {
    this.store = store;
    this.gasPrice = GasSpeed.Rapid;
    this.showNotification = this.notificationClosingThreshold < 3;

    extendObservable(this, {
      showNotification: this.showNotification,
      sidebarOpen: !!window && window.innerWidth > 960,
      notification: {},
      gasPrice: window.localStorage.getItem(`selectedGasPrice`) || "standard",
      txStatus: undefined,
    });
  }

  get notificationClosingThreshold(): number {
    return Number(window.localStorage.getItem(`closing-threshold`)) || 0;
  }

  get shouldShowNotification(): boolean {
    if (this.notificationClosingThreshold > 3) {
      return false;
    }

    return this.showNotification;
  }

  /* Load Operations */

  closeNotification = action(() => {
    window.localStorage.setItem(
      `closing-threshold`,
      String(this.notificationClosingThreshold + 1)
    );
    this.showNotification = false;
  });

  queueNotification = action(
    (message: string, variant: string, hash?: string) => {
      this.notification = { message, variant, persist: false, hash: hash };
    }
  );

  queueError(message: string): void {
    this.queueNotification(message, "error");
  }

  setGasPrice = action((gasPrice: GasSpeed) => {
    this.gasPrice = gasPrice;
    window.localStorage.setItem(`selectedGasPrice`, gasPrice);
  });
}

export default UiState;
