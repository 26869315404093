import React, { useContext } from "react";
import { Button, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { StoreContext } from "../../mobx/store-context";
import clsx from "clsx";
import { NETWORK_IDS } from "../../config/enums/network-ids.enum";
import { toHex } from "web3-utils";

const useStyles = makeStyles((theme) => ({
  walletDot: {
    display: "block",
    width: theme.spacing(0.9),
    height: theme.spacing(0.8),
    marginLeft: theme.spacing(0.4),
    borderRadius: theme.spacing(0.4),
  },
  redDot: {
    background: theme.palette.error.main,
  },
  greenDot: {
    background: theme.palette.success.main,
  },
  walletButton: {},
}));

const shortenAddress = (address?: string) => {
  if (!address) {
    return "Connect";
  }
  return (
    address.slice(0, 4) +
    ".." +
    address.slice(address.length - 4, address.length)
  );
};

interface Props {
  className?: HTMLButtonElement["className"];
}

const WalletWidget = observer(({ className }: Props) => {
  const classes = useStyles();
  const store = useContext(StoreContext);
  const { onboard, uiState } = store;
  const { address } = onboard;
  const isConnected = address !== undefined;
  const walletIcon = (
    <div
      className={clsx(
        classes.walletDot,
        isConnected ? classes.greenDot : classes.redDot
      )}
    />
  );

  window.ethereum?.on("chainChanged", (_chainId: any) => {
    if (toHex(NETWORK_IDS.MATIC) !== _chainId) {
      uiState.queueError("Wallet disconnected due to network change");
      window.location.reload();
    }
  });

  async function connect(): Promise<void> {
    if (onboard.address) {
      onboard.disconnect();
      uiState.queueNotification("Wallet disconnected", "info");
    } else {
      if (window.ethereum) {
        try {
          await window.ethereum?.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: toHex(NETWORK_IDS.MATIC) }],
          });
        } catch (switchError) {
          // 4902 means we were not able to switch to the desired network, good opportunity to ask if the user wants to add the network
          if (switchError.code === 4902) {
            try {
              await window.ethereum?.request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: toHex(NETWORK_IDS.MATIC),
                    chainName: "Polygon Mainnet",
                    nativeCurrency: {
                      name: "MATIC",
                      symbol: "MATIC",
                      decimals: 18,
                    },
                    rpcUrls: [
                      "https://polygon-rpc.com/",
                      "https://rpc-mainnet.matic.network",
                      "https://matic-mainnet.chainstacklabs.com",
                      "https://rpc-mainnet.maticvigil.com",
                      "https://rpc-mainnet.matic.quiknode.pro",
                      "https://matic-mainnet-full-rpc.bwarelabs.com",
                    ],
                    blockExplorerUrls: ["https://polygonscan.com"],
                  },
                ],
              });
            } catch (addError) {
              // handle "add" error
              uiState.queueError("Please select Polygon network first");
              onboard.disconnect();
              return;
            }
          }
          // handle other "switch" errors
          uiState.queueError("Please select Polygon network first");
          onboard.disconnect();
          return;
        }
      }
      const connected = await onboard.connect();
      if (!connected) {
        uiState.queueError("Issue connecting, please try again");
      } else {
        uiState.queueNotification("Wallet connected", "success");
      }
    }
  }

  return (
    <Button
      disableElevation
      variant="outlined"
      onClick={connect}
      endIcon={walletIcon}
      className={clsx(classes.walletButton, className)}
    >
      {shortenAddress(address)}
    </Button>
  );
});

export default WalletWidget;
