import { BigNumber } from "bignumber.js";
// ============== GENERAL HELPERS ==============

/* Breaks down a provided number and inserts appropriately placed commas to make a number more human readable
 * @param x = string formatted number to modify with commas
 * @return comma formatted number as a string
 */
export const numberWithCommas = (x: string): string => {
  const parts = x.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

/* Takes an input value and the old value and performs type coercion from string to
 * a BigNumber.  If it's not a valid input, returns the old value.
 * @param newValue = String input from an onChange event
 * @param oldValue = Previous value to revert to if an invalid input
 * @return string formatted number
 */
export const coerceInputToNumber = (newValue: string, oldValue: string) => {
  // Format to add a leading zero in front of decimals
  if (newValue === ".") newValue = "0.";

  // Format to remove leading zeros
  if (newValue.length >= 2 && newValue[0] === "0" && newValue[1] !== ".")
    newValue = newValue.substring(1);

  if (newValue === "") {
    return "0";
  }
  if (isNaN(newValue as unknown as number)) {
    return oldValue;
  } else {
    return newValue;
  }
};

export const convertFromWei = (input: BigNumber, decimals: number = 4) => {
  const output = input.dividedBy(1e18).toFormat(decimals);
  return output;
};
