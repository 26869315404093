import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    flexDirection: "column",
  },
  image: {
    maxWidth: "100%",
  },
}));

export const NotFound = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography>Page Not Found.</Typography>
    </div>
  );
};
