import { createTheme } from "@material-ui/core";

export const darkTheme = createTheme({
  palette: {
    type: "dark",
    primary: { main: "#8424E2" },
    secondary: { main: "#292044" },
    background: {
      default: "#170F2B",
      paper: "#312750",
    },
    success: {
      contrastText: "#ffffff",
      main: "#8424E2",
    },
    info: {
      contrastText: "#ffffff",
      main: "#8424E2",
    },
    error: {
      contrastText: "#ffffff",
      main: "#F4442E",
    },
    warning: {
      contrastText: "#181818",
      main: "#F2A52B",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: "'Roboto', 'IBM Plex Sans', sans-serif",
    h1: { fontWeight: 800, fontSize: "3rem" },
    h4: { fontSize: "1.3rem", fontWeight: 800 },
    h2: { fontSize: "2.2rem", fontWeight: 800, marginBottom: ".2rem" },
    h5: { fontWeight: 800 },
    subtitle1: { fontWeight: 600 },
    body1: { fontWeight: 600 },
  },
  shape: {
    borderRadius: 8,
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: ".95rem",
        backgroundColor: "#F2A52B",
        color: "#181818",
        fontWeight: 600,
        padding: ".5rem .8rem",
      },
      arrow: {
        color: "#F2A52B",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: "2.2rem",
        fontSize: "1rem",
      },
    },
    MuiListItemText: {
      primary: {
        fontSize: "inherit",
      },
    },
    MuiIcon: {},
    MuiDrawer: {
      paper: {
        background: "#121212",
      },
      paperAnchorDockedLeft: {
        borderRight: 0,
      },
    },
    MuiPaper: {
      outlined: {
        border: 0,
      },
    },
    MuiButton: {
      label: {
        fontWeight: 800,
      },
    },
  },
});
